import React, { useState, ChangeEvent } from 'react';
import styles from './Form.module.scss';

import EmailGeneratorLayout from './components/EmailGeneratorLayout';
import { SwingDevFormValues } from './types';
import { useDevTemplate } from './hooks';

const SwingDevGenerator = () => {
  const [formState, setFormState] = useState<SwingDevFormValues>({
    fullName: '',
    position: '',
    phone: '',
  });

  const template = useDevTemplate(formState);

  const updateFormInput = (e: ChangeEvent<HTMLInputElement>) => {
    setFormState({
      ...formState,
      [e.target.name]: e.target.value,
    });
  };

  const form = (
    <form className={styles.form}>
      <label htmlFor='fullName' className={styles.label}>
        Full name:
      </label>
      <input
        id='fullName'
        name='fullName'
        placeholder='Full Name'
        className={styles.input}
        value={formState.fullName}
        onChange={updateFormInput}
      />
      <label htmlFor='position' className={styles.label}>
        Position:
      </label>
      <input
        id='position'
        name='position'
        placeholder='Position'
        className={styles.input}
        value={formState.position}
        onChange={updateFormInput}
      />
      <label htmlFor='phone' className={styles.label}>
        Phone (please use +48 XXX XXX XXX format)
      </label>
      <input
        id='phone'
        name='phone'
        placeholder='Phone number'
        className={styles.input}
        value={formState.phone}
        onChange={updateFormInput}
      />
    </form>
  );

  return <EmailGeneratorLayout template={template} form={form} />;
};

export default SwingDevGenerator;
